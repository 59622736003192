'use client'

import '@/app/style/globals.scss'
import * as React from 'react'
import { redirect } from 'next/navigation'
import { getCurrentUserIsLoggedIn } from '@/lib/features/currentUser/currentUserSlice'
import { useAppSelector } from '@/lib/store'

interface Props {
  readonly children: React.ReactNode
}

export default (({ children }): React.ReactElement => {
  const isLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)

  if (isLoggedIn) {
    redirect('/generate')
  }

  return <div>{children}</div>
}) satisfies React.FC<Props>
